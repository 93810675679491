.bg-img 
{
	overflow: hidden;
	width: calc(100% - 180px);
	right: 0;
}

.text-welcome
 {
	line-height: 75px;
	font-size: 40px;
	margin-top: 9rem;
	font-weight: bold;
	margin-left: 4rem;
}

.text-fair 
{
	color: transparent;
	-webkit-text-stroke: 4px #C6AB52;
	text-stroke: 2px #000;
	margin: 0;
	font-size: 85px;
	font-weight: bolder;
}

.text-first 
{
	color: #000;
	font-size: 70px;
}

.admin-pn 
{
	width: 300px;
	background: black;
	color: #fff;
}

.Hero_Image
{
	max-height: 100%;
}

@media (max-width: 1200px) 
{
	.Hero_Image
	{
		display: none;
	}
}