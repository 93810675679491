.newafter::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: black;
  margin-left: 5px;
  /* Adjust the margin as needed */
}

.custom-select {
  color: #000;
  background-color: #c6ab52;
  padding: 8px 0px;
  border-radius: 4px;
  outline: none;
  border: none;
  width: 120px;
}

.addUser {
  padding: 2px 10px !important;
}

.masterFlow{
  margin: 2px;
  border-radius: 8px;
  background-color: #c6ab52;
  color: #fff;
}
.masterFlow p{
  margin: 0 8px;
  align-items: center;
}
.masterLabel{
  padding: 8px;
  border-right: 1px solid #fff;
  color: #000;
}

.masterform{
  display:flex;
  justify-content: center;
}

.player-w{
  background: #F64846 !important;
  color: #fff !important;
  border: none !important;
}
.player-d{
  background: #3EA662 !important;
  color: #fff !important;
  border: none !important;
}

.span-addplayer{
  cursor: pointer;
  margin-right: 20px;
}

.span-transferamt{
  vertical-align: middle;
  margin-left: 1rem;
  float: right;
}

.red {
  color: red;
}

.green {
  color: #2dba2d;
}

.password-message p{
  font-size: 11px;
  color: red;
  text-align: center;
}
